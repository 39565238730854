import { baseFetch, membersLink } from "@helium10/re-core";

const mainToolId = "data-helium10-main-tool-id";

const checkChromeExtensionExist = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!(window as any).chrome) {
    return null;
  } else {
    return document.body.getAttribute(mainToolId) || null;
  }
};
function extensionReady(callback: (extensionId: string | null) => void) {
  let extensionId = null;
  let tries = 0;
  const maxTries = 10;

  const intId = setInterval(function () {
    tries++;
    extensionId = checkChromeExtensionExist();

    if (extensionId || tries >= maxTries) {
      callback(extensionId);
      clearInterval(intId);
    }
  }, 100);
}

// https://github.com/helium10/app/blob/production/src/customer/assets/AppAsset/js/script.js#L104-L105
export const checkExtension = (accountId: string) => {
  if (typeof accountId !== "undefined" && accountId) {
    const millisecond = 1000;
    const chromeExtensionIdTtl = 6 * 3600 * millisecond;
    const now = new Date();
    const localStorageKey = "chromeExtension.id." + accountId;
    const extItem = JSON.parse(localStorage.getItem(localStorageKey) || "{}");
    const cacheAbsentOrExpired = !extItem.value || extItem.expiry <= now.getTime();

    if (cacheAbsentOrExpired) {
      extensionReady(function (extensionId) {
        baseFetch({
          url: membersLink + "/site/chrome-extension-installed?accountId=" + accountId,
          method: "POST",
          withCredentials: true,
          params: { isInstalled: Boolean(extensionId) },
        }).then(function (res) {
          if (res === "ok") {
            localStorage.setItem(
              localStorageKey,
              JSON.stringify({
                value: extensionId,
                expiry: new Date().getTime() + chromeExtensionIdTtl,
              }),
            );
          }
        });
      });
    }
  }
};
