import {
  amazonMarketsData,
  declareAction,
  declareAtom,
  getMarketInfo,
  map,
  walmartMarketsData,
} from "@helium10/re-core";
import { usePageStatus } from "@helium10/re-ui-components";

import type { IProfile } from "@/core/interfaces";
import { initialProfile } from "@/requests/getSubscriptions";
import { useSuggestionCount } from "@/store/account/suggestionsCount";

import { useCommonStore } from "../data/commonStore";
import { profileNotify } from "../notification/notificationsStore";
import { accountAtom } from "./accountsStore";
import { adtomicCustomerRequest, BILLING_TERMS_ACCEPTANCE_STATE } from "./customerStore";
import { getProfileSubscription, subscriptionsRequest } from "./subscriptionsStore";

const STORED_PROFILE = "storedProfileAdtomic";

export const setProfileAction = declareAction<{ profile: IProfile; subscribed?: boolean }>(
  "profile/setProfile",
  ({ profile, subscribed }, { dispatch, getState }) => {
    useCommonStore.getState().setProfile(profile);

    profile && localStorage.setItem(STORED_PROFILE, JSON.stringify(profile));
    const { role, permissions } = getState(accountAtom);
    const { content } = getState(adtomicCustomerRequest.atom);
    if (subscribed) {
      useSuggestionCount.getState().getSuggestionsCount.action();
    }

    if (content?.data?.billing_terms_acceptance_state !== BILLING_TERMS_ACCEPTANCE_STATE.ACCEPTED) {
      dispatch(profileNotify.owner.close());
      dispatch(profileNotify.guest.close());
    } else if (role === 1 || permissions.includes("subscribe")) {
      dispatch(subscribed ? profileNotify.owner.close() : profileNotify.owner.open());
    } else {
      dispatch(subscribed ? profileNotify.guest.close() : profileNotify.guest.open());
    }
  },
);

export const clearProfileAction = declareAction<void>("profile/clearProfile", () => {
  localStorage.removeItem(STORED_PROFILE);
});

export const setProfileByIdAction = declareAction<string>(
  "profile/setProfileById",
  (id, { dispatch, getState }) => {
    const { content } = getState(subscriptionsRequest.atom);
    usePageStatus.getState().setStatus("profile");
    const result = getProfileSubscription(id);
    content && dispatch(setProfileAction(result));
  },
);

const stored = localStorage.getItem(STORED_PROFILE);
export const initialProfileState = stored ? JSON.parse(stored) : initialProfile;

export const profileAtom = declareAtom<IProfile>("profileAtom", initialProfileState, (on) => [
  on(setProfileAction, (_, { profile }) => profile),
  on(clearProfileAction, () => initialProfile),
]);

export const marketplaceSelector = map("marketplaceSelector", profileAtom, (profile) => {
  const { data_provider_type, marketplace_string_id } = profile;
  switch (data_provider_type) {
    case "AMAZON":
      return getMarketInfo("AM", "store", marketplace_string_id) || amazonMarketsData.com;
    case "WALMART":
      return getMarketInfo("WALMART", "store", marketplace_string_id) || walmartMarketsData.com;
    default:
      return getMarketInfo("WALMART", "store", marketplace_string_id) || walmartMarketsData.com;
  }
});
